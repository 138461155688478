import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import settings from './settings/reducer';
import home from './home/reducer';

const persistConfig = {
  key: 'root',
  storage,
  transform: []
}


const rootReducer = combineReducers({
  settings,
  home
});

export default persistReducer(persistConfig, rootReducer);