module.exports = {
  /* 01.General */
  "general.search": "Search",
  "general.categories": "Categories",
  "general.see-it-all": "See it all",
  "general.menu": "Menu",
  "general.no-products": "No Products Available...",
  "general.find-us": "Find us on",
  "general.social-media": "Social Media",
  "general.powered-by": "Powered by",
  "general.humantwo": "HumanTwo",
  "general.offers": "Offers",
  "general.suggested-of-the-day": "Suggested of the Day",
  "general.ask-us": "Ask us",
  "general.not-available": "Not available",
  "general.all": "All",
  "general.information": "Information",
  "general.display-language": "Display Language",
  "general.languages": "Languages",
  "general.address": "Address",
  "general.contact-phone": "Contact Phone",
  "general.wifi-password": "WiFi Password",
  "general.no-password": "No Password",
  "general.available-payment-methods": "Available Payment Methods",
  "general.notes": "Notes",
  "general.debit": "Debit",
  "general.credit-card": "Credit Card",
  "general.cash": "Cash",
  "general.cancel": "Cancel",
  "general.save": "Save",
  "general.select-language": "Select Language",
};
