import {
    ACTION_HOME,
    FETCH_HOME,
    ERROR_FETCH_HOME,
    CLEAR_HOME,
    ACTION_CATEGORY,
    FETCH_CATEGORY,
    ERROR_FETCH_CATEGORY,
    FETCH_SUBCATEGORY,
    ERROR_FETCH_SUBCATEGORY,
    SET_SEARCH,
    SET_CATEGORIES,
    SET_PRODUCTS
} from './types';

const INIT_STATE = {
    homeData: {
        company: {},
        categories: [],
        suggested: [],
        offers: [],
    },
    subcategories: [],
    products: [],
    search: '',
    loading: true,
    loadingContent: false,
    error: ''
};


const home = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_HOME:
            return { ...state, loading: true, error: '' };
        case FETCH_HOME:
            return { ...state, loading: false, homeData: action.payload, error: '' };
        case ERROR_FETCH_HOME:
            return { ...state, loading: false, homeData: INIT_STATE.homeData, error: action.payload.message };
        case CLEAR_HOME:
            return { ...state, loading: false, homeData: INIT_STATE.homeData, subcategories: [], products: [], search: '' };
        case ACTION_CATEGORY:
            return { ...state, loadingContent: true, error: '' };
        case FETCH_CATEGORY:
            return { ...state, loadingContent: false, subcategories: action.payload.subcategories, products: action.payload.products, error: '' };
        case ERROR_FETCH_CATEGORY:
            return { ...state, loadingContent: false, subcategories: INIT_STATE.subcategories, products: INIT_STATE.products, error: action.payload.message };
        case FETCH_SUBCATEGORY:
            return { ...state, loadingContent: false, products: action.payload, error: '' };
        case ERROR_FETCH_SUBCATEGORY:
            return { ...state, loadingContent: false, products: INIT_STATE.products, error: action.payload.message };

        //options
        case SET_SEARCH:
            return { ...state,  search: action.payload };
        case SET_CATEGORIES:
            return { ...state,  homeData: action.payload };
        case SET_PRODUCTS:
            return { ...state,  products: action.payload };
        default: return state;
    }
}

export default home
