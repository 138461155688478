import React, { Suspense, Component } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import AppLocale from './lang';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import { LastLocationProvider } from 'react-router-last-location';

const ViewMain = React.lazy(() =>
  import('./views')
);
const ViewError = React.lazy(() =>
  import('./views/error')
);

class App extends Component {

  render() {
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale ? locale : "el"];
    return (
      <div className="catalogue-app">
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <Suspense fallback={<div className="loading-overlay" />}>
              <Router>
                <LastLocationProvider>
                  <Switch>
                    <Route
                      path="/"
                      exact
                      render={props => <ViewMain {...props} />}
                    />
                    <Route
                      path="/error"
                      exact
                      render={props => <ViewError {...props} />}
                    />
                    <Redirect to="/error" />
                  </Switch>
                </LastLocationProvider>
              </Router>
            </Suspense>
          </IntlProvider>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};

export default connect(mapStateToProps, null)(App);
