/**************** HOME *********************/

export const ACTION_HOME = "ACTION_HOME";
export const FETCH_HOME = "FETCH_HOME";
export const ERROR_FETCH_HOME = "ERROR_FETCH_HOME";

export const CLEAR_HOME = "CLEAR_HOME";

export const ACTION_CATEGORY = "ACTION_CATEGORY";
export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const ERROR_FETCH_CATEGORY = "ERROR_FETCH_CATEGORY";

export const FETCH_SUBCATEGORY = "FETCH_SUBCATEGORY";
export const ERROR_FETCH_SUBCATEGORY = "ERROR_FETCH_SUBCATEGORY";

export const SET_SEARCH = "SET_SEARCH";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_PRODUCTS = "SET_PRODUCTS";




