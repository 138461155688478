import appLocaleData from 'react-intl/locale-data/el';
import elMessages from '../locales/el_EL';

const ElLang = {
    messages: {
        ...elMessages
    },
    locale: 'el-EL',
    data: appLocaleData
};
export default ElLang;