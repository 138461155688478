import { CHANGE_LOCALE, SET_LOCALE_OPTIONS } from './types';

const INIT_STATE = {
	locale: localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : "",
	localOptions: []
};

const settings =  (state = INIT_STATE, action) => {
	switch (action.type) {
		case CHANGE_LOCALE:
			return { ...state, locale: action.payload };
		case SET_LOCALE_OPTIONS:
			return { ...state, localOptions: action.payload };

		default: return { ...state };
	}
}

export default settings;