module.exports = {
  /* 01.General */
  "general.search": "Αναζήτηση",
  "general.categories": "Κατηγορίες",
  "general.see-it-all": "Δες τα όλα",
  "general.menu": "Κατάλογος",
  "general.no-products": "Δεν υπάρχουν διαθέσιμα προϊόντα...",
  "general.find-us": "Βρείτε μας στα",
  "general.social-media": "Social Media",
  "general.powered-by": "Powered by",
  "general.humantwo": "HumanTwo",
  "general.offers": "Προσφορές",
  "general.suggested-of-the-day": "Προτεινόμενα Ημέρας",
  "general.ask-us": "Ρωτήστε μας",
  "general.not-available": "Μη διαθέσιμο",
  "general.all": "Ολα",
  "general.information": "Πληροφορίες",
  "general.display-language": "Γλώσσα Εμφάνισης",
  "general.address": "Διεύθυνση",
  "general.contact-phone": "Τηλέφωνο Επικοινωνίας",
  "general.wifi-password": "WiFi Password",
  "general.no-password": "Χωρίς Κωδικό Πρόσβασης",
  "general.available-payment-methods": "Διαθέσιμοι Τρόποι Πληρωμής",
  "general.notes": "Notes",
  "general.debit": "Χρεωστική",
  "general.credit-card": "Πιστωτική Κάρτα",
  "general.cash": "Μετρητά",
  "general.languages": "Γλώσσες",
  "general.cancel": "Ακύρωση",
  "general.save": "Αποθήκευση",
  "general.select-language": "Επιλέξτε γλώσσα",
};
